import React, { useEffect } from 'react';
import { ReactComponent as LoadingSpinner } from 'images/loader.svg';
import { ReactComponent as CheckIcon } from 'images/check-with-confetti.svg';
import { useDispatch, useSelector } from 'react-redux';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useQueryParams } from 'hooks/useQueryParam';
import { applyForCard, authSessionThunk, getPaycheckSwitchOffer, getStudentLoanAssistanceData } from 'thunks';
import { setCardData } from 'handlers/cardData';
import { trackConversionLead } from 'utils/analytics';
import { getUtmTagsVariables } from 'utils/getUtmTags';
import StateContainer from 'components/StateContainer';
import { getYourContactData } from 'selectors/getYourContact';
import { getYourNameData } from 'selectors/yourName';
import { getMethodAuthData } from 'selectors/methodAuth';
import { getCardData } from 'selectors/getCardData';
import { FlowComponentType } from 'routes/FlowRouter';
import { ApplyingResult } from 'enums/FlowNextResults';
import { useNavigationType } from 'hooks/useNavigate';
import { getAuthData } from 'selectors/getAuthData';
import { getYourIncome } from 'selectors/yourIncome';
import { getProfessionGroup } from 'selectors/professionGroup';
import { crossSell } from 'components/Card/Loader/Loader';
import { getPreQualificationData } from 'selectors/preQualificationData';
import { getClientTimezone } from 'utils/dateUtils';

import styles from './Applying.module.scss';

const Applying = ({ flags, handleNext }: FlowComponentType): JSX.Element => {
  const params = useQueryParams();
  const dispatch = useDispatch();
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigationType = useNavigationType();

  const { email, phone_number: phoneNumber } = useSelector(getYourContactData);
  const { first_name: firstName, last_name: lastName } = useSelector(getYourNameData);
  const {
    total_annual_income: totalAnnualIncome,
    start_of_employment: startOfEmployment,
    employer_name: employerName,
  } = useSelector(getYourIncome);
  const { professionGroup } = useSelector(getProfessionGroup);
  const { cardColor, borrowerCredentials, initialReferrer, referredBy, applied } = useSelector(getCardData);
  const { entityId, isLoading } = useSelector(getMethodAuthData);
  const { partnerName } = useSelector(getPreQualificationData);
  const { sessionToken } = useSelector(getAuthData);

  useEffect(() => {
    const apply = async () => {
      if (!entityId && isLoading) {
        return;
      }
      if (!entityId && !isLoading) {
        if (!sessionToken) {
          handleNext(ApplyingResult.Error);
          return;
        }
        dispatchWithUnwrap(
          authSessionThunk({
            phoneNumber,
            firstName,
            lastName,
            sessionToken,
          }),
        );
        return;
      }

      let cardAppliedResponse;
      try {
        cardAppliedResponse = await dispatchWithUnwrap(
          applyForCard({
            firstName,
            lastName,
            professionGroup,
            credentials: borrowerCredentials!,
            email,
            phoneNumber,
            cardColor: cardColor!,
            methodEntityId: entityId,
            utm: getUtmTagsVariables(params),
            httpReferrer: initialReferrer,
            cardReferredBy: referredBy,
            totalAnnualIncome: totalAnnualIncome ?? 0,
            employment: [
              {
                borrower_employer_name: employerName,
                hire_datetime: startOfEmployment!,
              },
            ],
            timezone: getClientTimezone(),
            resumeLink: `${window.location.href}`,
            partner: partnerName,
            sessionToken,
          }),
        );
      } catch (error) {
        handleNext(ApplyingResult.Error);
        return;
      }

      if (flags.enableStudentLoanAssistance) {
        await dispatchWithUnwrap(getStudentLoanAssistanceData({ applicationId: cardAppliedResponse.applicationId }));
      }

      if (flags.enableConnectPaycheckAfterCardApply) {
        await dispatchWithUnwrap(getPaycheckSwitchOffer(cardAppliedResponse.applicationId!));
      }

      if (cardAppliedResponse.applied) {
        dispatch(setCardData(cardAppliedResponse));
        handleNext(ApplyingResult.Done);
      }

      if (cardAppliedResponse.borrowerId) {
        analytics.identify(cardAppliedResponse.borrowerId);
        (window as any).nid('setUserId', cardAppliedResponse.borrowerId);
      }

      trackConversionLead({
        email,
        firstName,
        lastName,
        phoneNumber,
      });

      // Run x-sell loan offer for card application
      // Get loan offer gets data from current application
      // and labels it as a x-sell loan offer
      if (cardAppliedResponse.totalDebt !== null) {
        try {
          crossSell(dispatch, cardAppliedResponse.applicationId!);
        } catch (error) {
          // eslint-disable-next-line
          console.error(error);
        }
      }
    };

    if (navigationType === 'POP' && applied) {
      return;
    }
    apply();
  }, [entityId]);

  return (
    <div className={styles.container}>
      {navigationType === 'POP' && applied ? (
        <StateContainer icon={<CheckIcon />} title="Applied" />
      ) : (
        <StateContainer icon={<LoadingSpinner />} title="Applying..." />
      )}
    </div>
  );
};

export default Applying;
