import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import PageLoader from 'components/PageLoader';

import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { getStudentLoanData } from 'selectors/getStudentLoanData';
import { getStudentLoanAssistanceData } from 'thunks';
import { getCardData } from 'selectors/getCardData';
import { FlowComponentType } from 'routes/FlowRouter';

const StudentLoanLoaderWrapper = ({ children, flags }: Pick<FlowComponentType, 'children' | 'flags'>) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const { isLoading, fetched, refetch } = useSelector(getStudentLoanData);
  const { applicationId } = useSelector(getCardData);
  const { enableStudentLoanAssistance } = flags;

  useEffect(() => {
    if (enableStudentLoanAssistance && (isLoading || !fetched) && applicationId) {
      dispatchWithUnwrap(getStudentLoanAssistanceData({ applicationId }));
    }
  }, [applicationId]);

  if (enableStudentLoanAssistance && (isLoading || !fetched) && !refetch) {
    return <PageLoader />;
  }
  return <>{children}</>;
};

export default StudentLoanLoaderWrapper;
